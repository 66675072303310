import React from 'react'

import { Projects } from '../components/Projects/Projects' 



const Portfolio = () => {

  return (
    <>
      <Projects/>
    </>
  )
}

export default Portfolio